<template>
  <article-page :menu="menu">
    <div class="training-content" id="settings-page">
      <div class="_subtitle">
        Раздел "Настройки"
      </div>
      <span>
        Пользователям доступны различные настройки для достижения наилучшего взаимодействия с системой. Перейдя в раздел
        <accessed-link :name="Tabs.Settings.Main" target="_blank">"Настройки"</accessed-link>,
        Вы увидите все доступные подразделы, о которых будет рассказано ниже:
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt"  @click="openViewer(images, 0)"/>
      <span><b> Личные настройки: </b></span>
      <ul>
        <li>Настройки аккаунта;</li>
        <li>Настройки функциональности;</li>
        <li>Настройки уведомлений;</li>
      </ul>
      <span><b> Настройки компании: </b></span>
      <ul>
        <li>Настройки аккаунта компании;</li>
        <li>Управление заявками;</li>
        <li>Управление тарифами;</li>
        <li>Управление пользователями;</li>
        <li>Управление тегами;</li>
      </ul>
      <alert-message type="success" full-info>
        <template #content>
          Обратите внимание, в этой статьей упоминается совместная работа с другими пользователями, а также разграничение прав доступа для этих пользователей.
          Если Вам интересна совместная работа с Вашими коллегами под одним аккаунтом компании,
          можете прочитать статью
          <accessed-link :name="Tabs.Training.UserManagement">"Многопользовательский доступ"</accessed-link>.
        </template>
      </alert-message>
    </div>
    <div class="training-content" id="account-settings">
      <div class="_subtitle">
        Настройки аккаунта
      </div>
      <span>
        В разделе
        <accessed-link :name="Tabs.Settings.Account" target="_blank">"Мой профиль"</accessed-link>
        Вы можете заполнить информацию о себе, такую как фамилия и имя, контактный номер телефона и часовой пояс.
      </span>
      <span>
        Обратите внимание, телефон и адрес электронной почты, используемый для отправки уведомлений, можно настроить отдельно
        в разделе
        <accessed-link :name="Tabs.Settings.Notifications" target="_blank">Настройки уведомлений</accessed-link>.
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt" @click="openViewer(images, 1)"/>
      <span>
        В этом разделе Вы также можете изменить логин, пароль или обновить быструю ссылку для входа в свой профиль.
        Подробнее о доступных способах авторизации читайте
        <accessed-link :name="Tabs.Training.ChangePassword">в этой статье</accessed-link>.
      </span>
    </div>
    <div class="training-content" id="interface-settings">
      <div class="_subtitle">
        Настройки функциональности
      </div>
      <span>
        Для навигации по разделам используется боковое меню.
        При необходимости, Вы можете скрыть некоторые разделы, которые показались Вам излишними для комфортной работы.
      </span>
      <article-image :src="images[2].src" :alt="images[2].alt"  @click="openViewer(images, 2)"/>
      <span>
        В этом разделе Вы также можете изменить способ отметки лота просмотренным.
        Об этом можно прочесть в статье
        <accessed-link :name="Tabs.Training.WatchedLots">Отметка лотов просмотренными</accessed-link>.
      </span>
      <span>
        Кроме этого, в разделе доступны различные настройки, которые не описаны в этой статье.
        У каждой настройки есть подробное описание, объясняющая принцип их работы.
        Однако если у Вас будут вопросы, Вы можете обратится в службу поддержки по номеру, указаному в шапке сайта.
      </span>
    </div>
    <div class="training-content" id="notifications-settings">
      <div class="_subtitle">
        Настройка почтовых уведомлений
      </div>
      <span>
        В этом разделе Вы можете указать почтовый адрес для уведомлений, отличающийся от того, что Вы указали при регистрации.
        Уведомления, рассылаемые на почту, делятся на 2 вида:
      </span>
      <ul class="with-space">
        <li>
          <b>Системные уведомления:</b>
          <br /><br />
          <div>
            Эти уведомления всегда отправляются на почту, являющуюся <b>логином</b>. Изменить логин можно в разделе
            <accessed-link :name="Tabs.Settings.Account" target="_blank">"Мой профиль"</accessed-link>.
            Сюда входят уведомления о восстановлении пароля, смене быстрой ссылки, а также сообщения о регистрации или приглашении пользователя в компанию.
          </div>
        </li>
        <li>
          <b>Уведомления по шаблонам или событиям:</b>
          <br /><br />
          <div>
            Такие уведомления идут на <b>почту для уведомлений</b>, указанной в данном разделе.
            Рассылка включает в себя уведомления о новых тендерах, найденных по сохраненным шаблонам, а так же о датах подачи заявок, датах торгов, и датах подписания контрактов.
          </div>
        </li>
      </ul>
      <span>
        Данный раздел неразрывно связан с управлением заявками и контрактами, поэтому подробнее про настройку почтовых уведомлений Вы можете прочитать в разделах
        <accessed-link :name="Tabs.Training.Apps">Работа с заявками</accessed-link> и
        <accessed-link :name="Tabs.Training.Contracts">Работа с контрактами</accessed-link>.
      </span>
    </div>
    <div class="training-content" id="company-management">
      <div class="_subtitle">
        Настройки аккаунта компании
      </div>
      <span>
        Обратите внимание, просмотр раздела "Управление аккаунтом компании" доступен для всех пользователей,
        однако редактирование доступно только для пользователей с правами "Администратор".
      </span>
      <article-image :src="images[3].src" :alt="images[3].alt"  @click="openViewer(images, 3)"/>
    </div>
    <alert-message type="warning" full-info>
      <template #content>
        Нижеописанные разделы могут редактировать только пользователи с правами "Администратор".
      </template>
    </alert-message>
    <div class="training-content" id="system-settings">
      <div class="_subtitle">
        Системные настройки
      </div>
      <span>
        Настройки, устанавливаемые в разделе
        <accessed-link :name="Tabs.Settings.SystemManagement" target="_blank">"Системные настройки"</accessed-link>,
        распространяются на все аккаунты компании.
      </span>
      <ul class="with-space">
        <li>
          <b>Непросмотренные лоты</b>
          <br /><br />
          <div>
            После настройки и сохранения шаблона, новые закупки, относящиеся к шаблону, отмечаются непросмотренными и в поисковой выдаче находятся выше, чем просмотренные.
            Для того, чтобы непросмотренные лоты не накапливались, Вы можете указать количество дней, через которое все новые закупки будут автоматически отмечены как просмотренные.
          </div>
        </li>
        <li>
          <b>Архивные закупки</b>
          <br /><br />
          <div>
            Архивация доступна для заявок со статусами "отказ от закупки", "отклонено" и "поражение".
            Через указанное количество дней (первоначальная настройка - 30 календарных дней), закупка станет архивной.
            По умолчанию, архивные заявки не отображаются в списке заявок, однако Вы можете изменить это, нажав на галочку "Показывать архивные закупки".
          </div>
        </li>
      </ul>
      <article-image :src="images[4].src" :alt="images[4].alt" @click="openViewer(images, 4)"/>
    </div>
    <div class="training-content" id="tariff-management">
      <div class="_subtitle">
        Данные о подключенном тарифе
      </div>
      <span>
        Для просмотра информации о подключенном тарифе, перейдите в раздел
        <accessed-link :name="Tabs.Settings.Tariffs" target="_blank">"Управление тарифами"</accessed-link>.
        Там будет отображено название тарифа и дата его завершения. Для его продления выберите подходящий тариф и его длительность (1/6/12 месяцев)
        и нажмите кнопку <b>"Выбрать план"</b>.
        В появившемся диалоговом окне проверьте корректность заполненных данных, при необходимости оставьте комментарий и нажмите кнопку <b>Отправить заявку</b>.
      </span>
      <span>
        Наш менеджер свяжется с вами в ближайшее время для согласования стоимости услуги и уточнению дополнительной информации.
        Запрос на смену тарифа, наравне с заказанными услугами, будет отображен в разделе
        <accessed-link :name="Tabs.Services" target="_blank">"Услуги"</accessed-link>.
        Подробнее об услугах Вы можете почитать в статье
        <accessed-link :name="Tabs.Training.TenderServices">"Тендерные услуги"</accessed-link>.
      </span>
      <article-image :src="images[5].src" :alt="images[5].alt" width="520px" @click="openViewer(images, 5)"/>
    </div>
    <div class="training-content" id="tags-management">
      <div class="_subtitle">
        Управление тегами
      </div>
      <span>
        Последний раздел настроек позволяет создавать, редактировать и удалять теги. Подробная инструкция по работе с тегами описана в статье
        <accessed-link :name="Tabs.Training.Tags">"Работа с тегами"</accessed-link>.
      </span>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import { Tabs } from "@/router/tabs";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";

export default defineComponent({
  name: "AccountManagementArticle",
  components: {
    AccessedLink,
    AlertMessage,
    ArticleImage,
    ArticlePage,
  },
  setup() {

    const menu = [
      { id: 'settings-page', title: 'Раздел "Настройки"' },
      { id: 'account-settings', title: 'Настройки аккаунта' },
      { id: 'interface-settings', title: 'Настройки функциональности' },
      { id: 'notifications-settings', title: 'Настройки уведомлений' },
      { id: 'company-management', title: 'Настройки аккаунта компании' },
      { id: 'system-settings', title: 'Системные настройки' },
      { id: 'tariff-management', title: 'Данные о подключенном тарифе' },
      { id: 'tags-management', title: 'Управление тегами' },
    ]

    const images = [
      { src: 'settings/account-management/all-settings.png', alt: 'Раздел "Настройки"' },
      { src: 'settings/account-management/my-account.png', alt: 'Настройка личного профиля' },
      { src: 'settings/account-management/interface-settings.png', alt: 'Настройка бокового меню' },
      { src: 'settings/account-management/company-management.png', alt: 'Настройка аккаунта компании' },
      { src: 'settings/account-management/system-settings.png', alt: 'Системные настройки' },
      { src: 'settings/account-management/tariff-info.png', alt: 'Данные о подключенном тарифе' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      Tabs,
      openViewer,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';

.image-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
